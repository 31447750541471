<template>
  <v-dialog
    v-model="showModal"
    scrollable
  >
    <v-card>
      <v-card-title class="d-flex justify-space-between">
        Формула расчета ячейки
      </v-card-title>

      <v-card-text v-if="cellData">
        <v-row>
          <v-col cols="12">
            <v-select
              v-model="dataSel"
              :items="copmData"
              item-text="indexPlus"
              return-object
              label="Очередь выполнения"
              :disabled="cellData.length === 1"
            />
          </v-col>
        </v-row>
        <v-row v-if="dataSel">
          <v-col
            class="request-text"
            cols="6"
          >
            <v-btn
              icon
              color="grey"
              default
              rounded
              dark
              class="button-copy"
              @click="copyText(dataSel.dataquery)"
            >
              <v-icon>
                mdi-content-copy
              </v-icon>
            </v-btn>
            <p class="request-name my-0">Заполненый запрос</p>
            <prism
              class="prism"
              inline
              language="sql"
            >
              {{dataSel.dataquery}}
            </prism>
            <hr class="my-2">
          </v-col>
          <v-col
            class="request-text"
            cols="6"
          >
            <v-btn
              icon
              color="grey"
              default
              rounded
              dark
              class="button-copy"
              @click="copyText(dataSel.dataqueryparam)"
            >
              <v-icon>
                mdi-content-copy
              </v-icon>
            </v-btn>
            <p class="request-name my-0">Заполненый запрос</p>
            <prism
              class="prism"
              inline
              language="sql"
            >
              {{dataSel.dataqueryparam}}
            </prism>
            <hr class="my-2">
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="d-flex justify-space-around">
        <v-btn
          @click="showModal=false"
        >
          закрыть
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import 'prismjs'
  import 'prismjs/themes/prism.css'
  import 'prismjs/components/prism-sql'
  import Prism from 'vue-prism-component'
  function fallbackCopyTextToClipboard (text) {
    const textArea = document.createElement('textarea')
    textArea.value = text
    // Avoid scrolling to bottom
    textArea.style.top = '0'
    textArea.style.left = '0'
    textArea.style.position = 'fixed'
    document.body.appendChild(textArea)
    textArea.focus()
    textArea.select()
    try {
      const successful = document.execCommand('copy')
      const msg = successful ? 'successful' : 'unsuccessful'
      console.log('Fallback: Copying text command was ' + msg)
    } catch (err) {
      console.error('Fallback: Oops, unable to copy', err)
    }
    document.body.removeChild(textArea)
  }

  export default {
    name: 'CellFormula',
    components: {
      Prism,
    },
    computed: {
      cellData () {
        return this.$store.state.cellFormula.data || []
      },
      copmData () {
        const tempData = JSON.parse(JSON.stringify(this.cellData))
        tempData.forEach((item) => {
          item.indexPlus = item.rownum + ' [query id:' + item.querieid + ']'
        })
        return tempData
      },
      showModal: {
        get () {
          return this.$store.state.cellFormula.show
        },
        set (newval) {
          this.$store.commit('setCellFormulaShow', newval)
        },
      },
    },
    data () {
      return {
        dataSel: null,
        executeOrder: null,
      }
    },
    mounted () {
      this.$nextTick(() => {
        this.dataSel = this.copmData[0]
      })
    },
    methods: {
      copyText (text) {
        if (!navigator.clipboard) {
          fallbackCopyTextToClipboard(text)
          this.$store.dispatch('setSnackbar', { state: true, text: 'скопировано в буфер', color: 'success', direction: 'top center' })
          return
        }
        // eslint-disable-next-line no-use-before-define,no-undef
        const data = [new ClipboardItem({ 'text/plain': new Blob([text], { type: 'text/plain' }) })]
        navigator.clipboard.write(data).then(() => {
          console.log('скопировано в буфер', text)
          this.$store.dispatch('setSnackbar', { state: true, text: 'скопировано в буфер', color: 'success', direction: 'top center' })
        })
      },
    },
  }
</script>
<style lang="scss" scoped>
.request-text {
  position: relative;
  .button-copy {
    position: absolute;
    top: 0;
    right: 0;
  }
  .request-name {
    font-size: 12px;
    line-height: 14px;
    color: rgba(0, 0, 0, 0.3);
  }
  code {
    white-space: break-spaces;
    background: transparent;
  }
}
</style>
